body {
  margin: 0;
  padding: 0;
  background: #212529;
  color: #949ba1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", system-ui, Helvetica, Arial, sans-serif;
}

a {
  color: #c7cbcf;
}

a:hover, a:focus {
  color: #fff;
}

.About_about__3u5Aj {
  max-width: 650px;
  width: 90%;
  font-size: 1em;
  line-height: 1.4;
  text-align: left;
  margin: 0 auto;
}

.About_about__3u5Aj > div {
  padding-bottom: 5px;
}

.App_app__i6Hn0 {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App_header__1thcE {
  background-color: #222;
  border-bottom: 1px solid #40464b;
  padding: 5px;
  flex-shrink: 0;
}

.App_header__1thcE a {
  color: white;
  text-decoration: none;
}

.App_title__3ZcdF {
  font-size: 1.25em;
}

.App_logo__2TA53 {
  color: #ffffcc;
  height: 1.25em;
  width: 1.25em;
  padding-right: 5px;
  vertical-align: sub;
  -webkit-user-select: none;
          user-select: none;
}

.App_content__1l3kw {
  padding: 20px 0 35px;
  flex: 1 0 auto;
}

.App_disclaimer__3YSq- {
  padding-bottom: 20px;
  font-size: 10px;
  margin: 0 auto;
  width: 95%;
  max-width: 750px;
}

.App_footer__Wfgvv {
  margin: 0 auto;
  width: 95%;
  max-width: 650px;
  flex-shrink: 0;
  padding-bottom: 20px;
  color: #899097;
  font-size: 18px;
}

.App_footer__Wfgvv div {
  padding: 0 8px;
}

.App_footer__Wfgvv a {
  text-decoration: none;
}

.Utils_mobileShow__1wJUU {
  display: none;
  visibility: collapse;
}

@media all and (max-width: 479px) {
  .Utils_mobileHide__3g5bT {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: collapse;
  }

  .Utils_mobileShow__1wJUU {
    display: inline;
    visibility: visible;
  }
}

.Landing_progress__1tceT div {
  background: yellow;
  box-shadow: 0 0 2px yellow;
}

.Landing_header__ueI9P {
}

.Landing_landing__2aH6O {
  width: 95%;
  max-width: 750px;
  margin: 0 auto;
}

.Landing_logo__3eVVJ {
  cursor: default;
  -webkit-user-select: none;
          user-select: none;
  padding-top: 150px;
}

.Landing_logo__3eVVJ img {
  color: #ffffcc;
  height: 90px;
  width: 90px;
}

.Landing_title__8extl {
  color: #fff;
  font-weight: bold;
  font-size: 1.75em;
  padding-bottom: 2em;
}

.Landing_summonerSelect__1K3sq {
  font-size: 1.5em;
  max-width: 600px;
  width: 95%;
  color: #4a4d50;
  margin: 0 auto;
  padding-bottom: 5px;
  margin-bottom: 5px;
  position: relative;
  border-bottom: 2px solid #fce84e;
}

.Landing_summonerSelectMessage__1BS8X {
  border-bottom-color: orange;
}

.Landing_summonerSelect__1K3sq input {
  font-size: inherit;
  border: 0;
  outline: 0;
  background: transparent;
  color: #949ba1;
  padding: 0 40px 0 20px;
  width: 520px;
  display: block;
}

.Landing_summonerSelect__1K3sq input::-webkit-input-placeholder {
  color: #4a4d50;
}

.Landing_summonerSelect__1K3sq input::placeholder {
  color: #4a4d50;
}

.Landing_summonerSelect__1K3sq input:disabled {
  color: #3b3e40;
}

.Landing_summonerSelect__1K3sq button {
  position: absolute;
  cursor: pointer;
  background: none;
  color: #4a4d50;
  font-size: inherit;
  width: 30px;
  margin-top: 2px;
  margin-bottom: 2px;
  top: -2px;
  right: 0;
  border: none;
}

.Landing_summonerSelect__1K3sq button:hover {
  color: #949ba1;
  outline: none;
}

.Landing_summonerSelect__1K3sq button:active {
  color: white;
  outline: none;
}

.Landing_summonerSelect__1K3sq button:disabled {
  color: #3b3e40;
}

.Landing_message__3aY3Y {
  position: absolute;
  color: orange;
  padding-top: 10px;
  font-size: 0.5em;
  left: 10px;
}

.Landing_regionSelect__1ANDB {
  max-width: 600px;
  width: 95%;
  margin: 0 auto 25px;
  text-align: right;
}

.Landing_regionSelect__1ANDB span {
  padding-right: 5px;
}

.Landing_regionSelect__1ANDB select {
  background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px'><polyline points='46.139,15.518 25.166,36.49 4.193,15.519' stroke='white' fill='white'/></svg>");
  background-color: #343434;
  background-repeat: no-repeat;
  background-position: right 5px top 7px;
  background-size: 12px 12px;
  color: white;
  padding: 4px 22px 4px 6px;
  font-size: 14px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-appearance: none;
  -webkit-user-select: none;
          user-select: none;
  border: 0;
  outline: 0;
  text-align: center;
  text-align-last: center;
}

.Landing_starred__3Rt5x {
  max-width: 600px;
  width: 95%;
  margin: 0 auto;
  padding: 5px;
  text-align: left;
}

.Landing_remove__S0_Hh {
  padding-right: 5px;
  color: #545454;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.Landing_remove__S0_Hh:hover {
  color: #fff;
}

ul {
  list-style: none;
  padding-left: 1ch;
  margin-top: 3px;
}

ul, ul li {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.9em;
  line-height: 1.4em;
}

.Landing_infoWrapper__2v-ob {
  padding-top: 25px;
  display: flex;
  align-content: space-between;
}

.Landing_infoWrapper__2v-ob > div {
  width: 325px;
}

.Landing_infoWrapper__2v-ob > div span {
  width: px;
}

.Landing_infoWrapper__2v-ob > div:first-child {
  margin-right: auto;
}

.Landing_infoWrapper__2v-ob > div:last-child {
  margin-left: auto;
}

.Landing_infoWrapper__2v-ob h4 {
  margin-bottom: 5px;
}

.Landing_infoWrapper__2v-ob > div > span {
  font-size: 0.9em;
}

.Landing_infoImage__2X9M5 {
  margin-top: 20px;
  height: 200px;
  position: relative;
}

.Landing_infoImage__2X9M5 img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  /*top: 0;*/
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@media all and (max-width: 699px) {
  .Landing_infoWrapper__2v-ob {
    flex-direction: column;
  }

  .Landing_infoWrapper__2v-ob > div {
    margin: auto;
  }

  .Landing_infoWrapper__2v-ob > div:first-child {
    margin-bottom: 20px;
  }
}

@media all and (max-width: 479px) {
  div.Landing_progress__1tceT {
    position: static;
  }

  .Landing_summonerSelect__1K3sq {
    font-size: 1em;
    margin-top: 50px;
    width: 90%;
  }

  .Landing_summonerSelect__1K3sq div {
    width: 25px;
  }

  .Landing_summonerSelect__1K3sq input {
    font-size: 1em;
    width: -o-calc(100% - 25px);
    width: calc(100% - 25px);
    padding-right: 25px;
  }

  .Landing_summonerSelect__1K3sq button {
    height: 18px;
    width: 25px;
    line-height: 0.5em;
  }

  .Landing_regionSelect__1ANDB {
    margin: 0 auto 25px;
    width: 90%;
    text-align: right;
  }

  .Landing_starred__3Rt5x {
    width: 90%;
  }
}

.Leaderboard_message__2bq5_ {
  max-width: 90%;
  font-size: 0.8em;
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
  padding: 6px 4px;
  background-color: #2c3237;
  border: 1px solid #40464b;
  border-radius: 2px;
  margin-top: -5px;
  margin-bottom: 10px;
}

.Leaderboard_exclaim__2OqI9, .Leaderboard_close__1FFKW {
  padding: 0 8px;
  -webkit-user-select: none;
          user-select: none;
}

.Leaderboard_close__1FFKW {
  color: #545454;
  cursor: pointer;
}

.Leaderboard_close__1FFKW:hover {
  color: white;
}

.Leaderboard_chart__fIK0F {
  padding-bottom: 20px;
  max-width: 960px;
  margin: auto;
  padding-left: 2px;
  padding-right: 6px;
}

.Leaderboard_scale__1lnnB {
  float: right;
  padding-right: 1.5em;
  -webkit-font-feature-settings: "c2sc";
          font-feature-settings: "c2sc";
  font-variant: small-caps;
  -webkit-user-select: none;
          user-select: none;
}

.Leaderboard_noChart__2xu4j {
  height: 30px;
  font-style: italic;
  clear: both;
}

.Leaderboard_divider__1BAuL {
  padding: 0px 6px;
  vertical-align: middle;
}

.Leaderboard_scaleSelected__2ozYT, .Leaderboard_scaleUnselected__3vnnF, .Leaderboard_star__AYqba, .Leaderboard_starred__2ZndD, .Leaderboard_refresh__1WyIW, .Leaderboard_refreshing__dykCs {
  padding: 0px 4px;
  cursor: pointer;
  vertical-align: middle;
}

.Leaderboard_star__AYqba {
  color: #dee2e6;
}

.Leaderboard_star__AYqba:hover, .Leaderboard_starred__2ZndD {
  color: #fce84e;
}

.Leaderboard_refresh__1WyIW {
  color: #dee2e6;
}

.Leaderboard_refreshing__dykCs {
  cursor: default;
}

.Leaderboard_disabledRefresh__1eXtk {
  padding: 0px 4px;
  color: #555;
  vertical-align: middle;
}

.Leaderboard_scaleUnselected__3vnnF {
  font-size: 0.8em;
  color: #dee2e6;
}

.Leaderboard_refresh__1WyIW:hover, .Leaderboard_scaleUnselected__3vnnF:hover {
  color: #fff;
}

.Leaderboard_scaleSelected__2ozYT {
  font-weight: bold;
  text-decoration: underline;
}

.Leaderboard_summonerColumn__2mjmS, .Leaderboard_rankColumn__2IE91 {
  padding: 2px 20px;
}

.Leaderboard_tagline__3yVMq {
  opacity: 0.7;
}

.Leaderboard_rankColumn__2IE91 {
  padding-left: 5px;
}

.Leaderboard_deltaColumn__bF5zK {
  -webkit-user-select: none;
          user-select: none;
  padding: 2px 4px;
  font-size: 0.6em;
  text-align: right;
}

table {
  border-collapse: collapse;
}

.Leaderboard_tableRow__3-U_l:hover {
  background-color: #4d5053;
}

.Leaderboard_unrankedRow__geSbY {
  opacity: 0.8;
}

.Leaderboard_series__xbjiy span {
  width: 12px;
  -webkit-user-select: none;
          user-select: none;
  text-align: center;
  display: inline-block;
}

.Leaderboard_circle__2rAOF {
  -webkit-user-select: none;
          user-select: none;
  height: 0.75em;
  width: 0.75em;
  border: solid;
  border-width: 2px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 1px;
  vertical-align: text-bottom;
}

.Leaderboard_dot__2T-Tn {
  cursor: pointer;
}

.Leaderboard_dot__2T-Tn:hover {
  opacity: 0.6;
}

.Leaderboard_disabledDot__2aZ61 {
  opacity: 0.6;
}

@media all and (max-width: 479px) {
  .Leaderboard_series__xbjiy {
    padding-left: 2px;
    padding-right: 2px;
  }

  .Leaderboard_series__xbjiy .Leaderboard_diamond__dkxVG {
    font-size: 0.65em;
    vertical-align: middle;
  }

  .Leaderboard_noChart__2xu4j {
    padding-top: 20px;
  }
}

.Leaderboard_rankColumnDesktop__3mwFx {
}

.Leaderboard_rankColumnMobile__2lsRd {
}

.Patreon_patreon__35zax {
    max-width: 650px;
    width: 90%;
    font-size: 1em;
    line-height: 1.5;
    text-align: left;
    margin: 0 auto;
}

.Patreon_patreon__35zax h1 {
    margin: 0 auto;
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 15px;
}
  
.Patreon_patreon__35zax p {
    padding-bottom: 10px;
}
  
