.app {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  background-color: #222;
  border-bottom: 1px solid #40464b;
  padding: 5px;
  flex-shrink: 0;
}

.header a {
  color: white;
  text-decoration: none;
}

.title {
  font-size: 1.25em;
}

.logo {
  color: #ffffcc;
  height: 1.25em;
  width: 1.25em;
  padding-right: 5px;
  vertical-align: sub;
  user-select: none;
}

.content {
  padding: 20px 0 35px;
  flex: 1 0 auto;
}

.disclaimer {
  padding-bottom: 20px;
  font-size: 10px;
  margin: 0 auto;
  width: 95%;
  max-width: 750px;
}

.footer {
  margin: 0 auto;
  width: 95%;
  max-width: 650px;
  flex-shrink: 0;
  padding-bottom: 20px;
  color: #899097;
  font-size: 18px;
}

.footer div {
  padding: 0 8px;
}

.footer a {
  text-decoration: none;
}
