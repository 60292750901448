.progress div {
  background: yellow;
  box-shadow: 0 0 2px yellow;
}

.header {
  composes: mobileShow from "./Utils.module.css";
}

.landing {
  width: 95%;
  max-width: 750px;
  margin: 0 auto;
}

.logo {
  cursor: default;
  user-select: none;
  padding-top: 150px;
  composes: mobileHide from "./Utils.module.css";
}

.logo img {
  color: #ffffcc;
  height: 90px;
  width: 90px;
}

.title {
  color: #fff;
  font-weight: bold;
  font-size: 1.75em;
  padding-bottom: 2em;
}

.summonerSelect {
  font-size: 1.5em;
  max-width: 600px;
  width: 95%;
  color: #4a4d50;
  margin: 0 auto;
  padding-bottom: 5px;
  margin-bottom: 5px;
  position: relative;
  border-bottom: 2px solid #fce84e;
}

.summonerSelectMessage {
  composes: summonerSelect;
  border-bottom-color: orange;
}

.summonerSelect input {
  font-size: inherit;
  border: 0;
  outline: 0;
  background: transparent;
  color: #949ba1;
  padding: 0 40px 0 20px;
  width: 520px;
  display: block;
}

.summonerSelect input::placeholder {
  color: #4a4d50;
}

.summonerSelect input:disabled {
  color: #3b3e40;
}

.summonerSelect button {
  position: absolute;
  cursor: pointer;
  background: none;
  color: #4a4d50;
  font-size: inherit;
  width: 30px;
  margin-top: 2px;
  margin-bottom: 2px;
  top: -2px;
  right: 0;
  border: none;
}

.summonerSelect button:hover {
  color: #949ba1;
  outline: none;
}

.summonerSelect button:active {
  color: white;
  outline: none;
}

.summonerSelect button:disabled {
  color: #3b3e40;
}

.message {
  position: absolute;
  color: orange;
  padding-top: 10px;
  font-size: 0.5em;
  left: 10px;
}

.regionSelect {
  max-width: 600px;
  width: 95%;
  margin: 0 auto 25px;
  text-align: right;
}

.regionSelect span {
  padding-right: 5px;
}

.regionSelect select {
  background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px'><polyline points='46.139,15.518 25.166,36.49 4.193,15.519' stroke='white' fill='white'/></svg>");
  background-color: #343434;
  background-repeat: no-repeat;
  background-position: right 5px top 7px;
  background-size: 12px 12px;
  color: white;
  padding: 4px 22px 4px 6px;
  font-size: 14px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -webkit-appearance: none;
  user-select: none;
  border: 0;
  outline: 0;
  text-align: center;
  text-align-last: center;
}

.starred {
  max-width: 600px;
  width: 95%;
  margin: 0 auto;
  padding: 5px;
  text-align: left;
}

.remove {
  padding-right: 5px;
  color: #545454;
  cursor: pointer;
  user-select: none;
}

.remove:hover {
  color: #fff;
}

ul {
  list-style: none;
  padding-left: 1ch;
  margin-top: 3px;
}

ul, ul li {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.9em;
  line-height: 1.4em;
}

.infoWrapper {
  padding-top: 25px;
  display: flex;
  align-content: space-between;
}

.infoWrapper > div {
  width: 325px;
}

.infoWrapper > div span {
  width: px;
}

.infoWrapper > div:first-child {
  margin-right: auto;
}

.infoWrapper > div:last-child {
  margin-left: auto;
}

.infoWrapper h4 {
  margin-bottom: 5px;
}

.infoWrapper > div > span {
  font-size: 0.9em;
}

.infoImage {
  margin-top: 20px;
  height: 200px;
  position: relative;
}

.infoImage img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  /*top: 0;*/
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@media all and (max-width: 699px) {
  .infoWrapper {
    flex-direction: column;
  }

  .infoWrapper > div {
    margin: auto;
  }

  .infoWrapper > div:first-child {
    margin-bottom: 20px;
  }
}

@media all and (max-width: 479px) {
  div.progress {
    position: static;
  }

  .summonerSelect {
    font-size: 1em;
    margin-top: 50px;
    width: 90%;
  }

  .summonerSelect div {
    width: 25px;
  }

  .summonerSelect input {
    font-size: 1em;
    width: -moz-calc(100% - 25px);
    width: -webkit-calc(100% - 25px);
    width: -o-calc(100% - 25px);
    width: calc(100% - 25px);
    padding-right: 25px;
  }

  .summonerSelect button {
    height: 18px;
    width: 25px;
    line-height: 0.5em;
  }

  .regionSelect {
    margin: 0 auto 25px;
    width: 90%;
    text-align: right;
  }

  .starred {
    width: 90%;
  }
}
