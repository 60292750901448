.mobileShow {
  display: none;
  visibility: collapse;
}

@media all and (max-width: 479px) {
  .mobileHide {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: collapse;
  }

  .mobileShow {
    display: inline;
    visibility: visible;
  }
}
