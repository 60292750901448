.about {
  max-width: 650px;
  width: 90%;
  font-size: 1em;
  line-height: 1.4;
  text-align: left;
  margin: 0 auto;
}

.about > div {
  padding-bottom: 5px;
}
