.message {
  max-width: 90%;
  font-size: 0.8em;
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
  padding: 6px 4px;
  background-color: #2c3237;
  border: 1px solid #40464b;
  border-radius: 2px;
  margin-top: -5px;
  margin-bottom: 10px;
}

.exclaim, .close {
  padding: 0 8px;
  user-select: none;
}

.close {
  color: #545454;
  cursor: pointer;
}

.close:hover {
  color: white;
}

.chart {
  padding-bottom: 20px;
  max-width: 960px;
  margin: auto;
  padding-left: 2px;
  padding-right: 6px;
}

.scale {
  float: right;
  padding-right: 1.5em;
  font-variant: small-caps;
  user-select: none;
}

.noChart {
  height: 30px;
  font-style: italic;
  clear: both;
}

.divider {
  padding: 0px 6px;
  vertical-align: middle;
}

.scaleSelected, .scaleUnselected, .star, .starred, .refresh, .refreshing {
  padding: 0px 4px;
  cursor: pointer;
  vertical-align: middle;
}

.star {
  color: #dee2e6;
}

.star:hover, .starred {
  color: #fce84e;
}

.refresh {
  color: #dee2e6;
}

.refreshing {
  cursor: default;
}

.disabledRefresh {
  padding: 0px 4px;
  color: #555;
  vertical-align: middle;
}

.scaleUnselected {
  font-size: 0.8em;
  color: #dee2e6;
}

.refresh:hover, .scaleUnselected:hover {
  color: #fff;
}

.scaleSelected {
  composes: scaleUnselected;
  font-weight: bold;
  text-decoration: underline;
}

.summonerColumn, .rankColumn {
  padding: 2px 20px;
}

.tagline {
  opacity: 0.7;
}

.rankColumn {
  padding-left: 5px;
}

.deltaColumn {
  user-select: none;
  padding: 2px 4px;
  font-size: 0.6em;
  text-align: right;
}

table {
  border-collapse: collapse;
}

.tableRow:hover {
  background-color: #4d5053;
}

.unrankedRow {
  opacity: 0.8;
}

.series span {
  width: 12px;
  user-select: none;
  text-align: center;
  display: inline-block;
}

.circle {
  user-select: none;
  height: 0.75em;
  width: 0.75em;
  border: solid;
  border-width: 2px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 1px;
  vertical-align: text-bottom;
}

.dot {
  composes: circle;
  cursor: pointer;
}

.dot:hover {
  opacity: 0.6;
}

.disabledDot {
  composes: circle;
  opacity: 0.6;
}

@media all and (max-width: 479px) {
  .series {
    padding-left: 2px;
    padding-right: 2px;
  }

  .series .diamond {
    font-size: 0.65em;
    vertical-align: middle;
  }

  .noChart {
    padding-top: 20px;
  }
}

.rankColumnDesktop {
  composes: rankColumn;
  composes: mobileHide from "./Utils.module.css";
}

.rankColumnMobile {
  composes: rankColumn;
  composes: mobileShow from "./Utils.module.css";
}
