body {
  margin: 0;
  padding: 0;
  background: #212529;
  color: #949ba1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", system-ui, Helvetica, Arial, sans-serif;
}

a {
  color: #c7cbcf;
}

a:hover, a:focus {
  color: #fff;
}
