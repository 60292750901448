.patreon {
    max-width: 650px;
    width: 90%;
    font-size: 1em;
    line-height: 1.5;
    text-align: left;
    margin: 0 auto;
}

.patreon h1 {
    margin: 0 auto;
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 15px;
}
  
.patreon p {
    padding-bottom: 10px;
}
  